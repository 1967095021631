import breakpoints from '../utils/breakpoints';

export default class NavigationSubMenu {
    constructor(submenu) {
        this.$wrapper = submenu;
        this.$openAction = this.$wrapper.querySelector('[data-submenu-action]');
        this.$closeAction = this.$wrapper.querySelector(
            '[data-navigation-back]'
        );
        this.$submenu = this.$wrapper.querySelector(
            '[data-navigation-submenu]'
        );
        this.levels = this.$submenu.dataset.levels;
        this.mobileMediaQuery = window.matchMedia(
            `(max-width: ${breakpoints.wide}px)`
        );
        this.addEventListeners();
    }

    addEventListeners() {
        this.$openAction.addEventListener('click', () => {
            this.open();
        });

        this.$closeAction.addEventListener('click', () => {
            this.close();
        });

        // Listens for an event which is fired when the drawer closes
        window.addEventListener('drawer.closed', () => {
            this.close();
        });
    }

    open() {
        this.$submenu.setAttribute('aria-hidden', 'false');
    }
    
    close() {
        this.$submenu.setAttribute('aria-hidden', 'true');
    }
}
